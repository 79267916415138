import React from 'react';
import { Helmet } from 'react-helmet';
import { Layout } from '../components/Layout';
import { IndexSplash, IndexInfo, IndexIntro } from '../components/UI/IndexUI';

export default () => (
  <Layout>
    <Helmet>
      <meta name="description" content="JMParsons Skills" />
    </Helmet>
    <IndexSplash>
      <IndexInfo>
        <IndexIntro>Skills List</IndexIntro>
      </IndexInfo>
    </IndexSplash>
  </Layout>
);
